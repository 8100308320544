"use strict";

(function () {
  var iconHappy = 'icon icon-happy ';
  var iconSad = 'icon icon-sad';
  var iconNeutral = 'icon icon-blankstare';
  var polarityIconClassesInfo = {
    "default": {
      icon: iconHappy
    },
    1: {
      icon: iconHappy
    },
    2: {
      icon: iconSad
    },
    3: {
      icon: iconNeutral
    }
  };
  var positive = {
    iconClasses: iconHappy,
    mainIconSuffix: 'happy',
    value: 1,
    title: 'Positivo'
  };
  var neutral = {
    iconClasses: iconNeutral,
    mainIconSuffix: 'blankstare',
    value: 3,
    title: 'Neutro'
  };
  var negative = {
    iconClasses: iconSad,
    mainIconSuffix: 'sad',
    value: 2,
    title: 'Negativo'
  };
  var polarities = {
    "default": positive,
    positive: positive,
    neutral: neutral,
    negative: negative
  };
  function getPolarityLabelByClassOrNumber() {
    var icon = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
    var polarity = Object.entries(polarities).filter(function (entry) {
      var filter = entry[1].value === icon || entry[0] === icon.toString().trim().toLowerCase();
      return filter;
    })[0];
    if (!polarity) {
      return '';
    }
    return polarity[1].title;
  }
  window.PolaritiesInfo = {
    polarities: polarities,
    iconClasses: polarityIconClassesInfo,
    getPolarityLabelByClassOrNumber: getPolarityLabelByClassOrNumber
  };
})();